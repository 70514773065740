import React from 'react';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';

import { useQuery } from '@apollo/react-hooks';
import Playlist from './Playlist';

import Loading from '../../Auth/Loading';

const GetPlaylistQuery = loader('../../GraphQL/GetPlaylist.gql');

function PlaylistContainer({ match }) {

  const { id } = match.params;
  const { loading, error, data } = useQuery(GetPlaylistQuery, { variables: { id: parseInt(id) } });

  if(loading)
    return <Loading />

  return (
    <div>
      {!error ? <Playlist playlist={data.getPlaylist} /> : null}
    </div>
  );
}

export default withRouter(PlaylistContainer);
