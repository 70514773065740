import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/browser";

import { AuthProvider } from './Auth/AuthProvider';

import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({ dsn: "https://ee9f4752dad34ead8bb720611360c572@o373975.ingest.sentry.io/5191303" });

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

ReactDOM.render(
  <AuthProvider
    domain="samfundet-lydbok.eu.auth0.com"
    client_id="dQJpXM9jA7NHpaPwIrcukkgrsvyK8CG5"
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </AuthProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();