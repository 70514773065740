import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import detectBrowser from "browser-detect";

import { Form, Container, ListGroup } from 'react-bootstrap';
import PlaylistCard from './PlaylistCard';

import { loader } from 'graphql.macro';
import CustomAlert from '../Utils/CustomAlert';
import { useParams } from 'react-router-dom';
import Loading from '../../Auth/Loading';
import BrowserWarning from '../BrowserWarning';

const GetCollectionQuery = loader('../../GraphQL/GetCollection.gql');

export default function Collection(props) {

  const [keyword, setKeyword] = useState("");

  let { id } = useParams();

  const { loading, error, data } = useQuery(GetCollectionQuery, { variables: { id: parseInt(id) } });

  const collection = data ? data.getCollection : { title: "", items: [] }
  const filteredPlaylists = collection.items.filter(e => e.playlist.title.toLowerCase().includes(keyword.toLowerCase()))

  const playlistCards = filteredPlaylists.map(e => <PlaylistCard playlist={e.playlist} />);

  if(loading)
    return <Loading />;

  const result = detectBrowser();
  const isBrowserRecommended = result.name === "chrome";
  
  return (
    <div>
      <Container>
        <div style={{ marginTop: "20px" }}>
          <h4 style={{ fontWeight: "600" }}>{collection.title}</h4>
          <span style={{ color: "#696969" }}>
            {collection.items.length} kapittel.
          </span>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div>
            <Form.Control
              value={keyword}
              as="input"
              type="text"
              placeholder="Søk etter innhold i lydboken..."
              onChange={(event) => setKeyword(event.target.value)}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <CustomAlert isVisible={!!error} title="Det oppsto en feil" />
            <ListGroup variant="flush">{playlistCards}</ListGroup>
          </div>
        </div>
      </Container>
    </div>
  );
}
