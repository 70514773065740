import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

export default function CustomAlert({ isVisible, title, message }) {
  return (
    <div>
      {isVisible ?
        <Alert variant="danger">
          <Alert.Heading>{title}</Alert.Heading>
          <p>{message}</p>
        </Alert>
        : null
      }
    </div>
  );
}

CustomAlert.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.String,
  isVisible: PropTypes.bool
};

CustomAlert.defaultProps = {
  title: true,
  message: "",
  isVisible: true
};

