import React from 'react';
import { Container } from 'react-bootstrap';


export default function Loading(props) {
  return (
    <div style={{ marginTop: "10px" }}>
      <Container>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h4>Laster innhold</h4>
          <img src="/icons/loading.gif" alt="loading" />
        </div>
      </Container>
    </div>
  );
}
