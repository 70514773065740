import React from 'react';

import moment from 'moment';
import { Button, Container } from 'react-bootstrap';

export default function Login(props) {
  const { login } = props;

  const style = {
    marginTop: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const metaStyle = {
    margin: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Container>
      <div style={style}>
        <div>
          <h2>Velkommen til Lydbøker</h2>
          <p>
            For å kunne logge på tjenesten trenger du en gyldig{" "}
            <b>@samfundet.org</b> epostadresse.
          </p>
          <Button variant="outline-primary" onClick={() => login()}>
           Klikk her for å logge inn
          </Button>
        </div>
      </div>
      <hr />
      <div style={metaStyle}>
        <p>Menigheten Samfundets Bibelverktøy - {moment().year()}</p>
      </div>
      <div style={metaStyle}>
        <p>
          Trender du hjelp? <b>post@samfundet.org</b>
        </p>
      </div>
    </Container>
  );
}
