import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Wrapper from './Components/Wrapper';

function App() {

  const client = new ApolloClient({
    uri: 'https://audio-api-dot-bible-250607.appspot.com/graphql',
    // uri: "http://localhost:4000/graphql"
  });

  return (
    <div style={{ height: "100%" }}>
      <Router>
        <ApolloProvider client={client}>
          <Wrapper />
        </ApolloProvider>
      </Router>
    </div>
  );
}

export default App;
