import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Navbar, Nav, Form, Container, Button } from 'react-bootstrap';

import './AppNavbar.css';

import { useAuth } from '../Auth/AuthProvider';

function AppNavbar(props) {
  const { history } = props;

  const { logout, isAuthenticated } = useAuth();

  return (
    <div className="navbar-container" id="navbar">
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src="/logo.svg"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Lydbøker
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Link to="/collection/2">
                <Nav.Link onClick={() => history.push("/collection/3")}>
                  Det nye testamente
                </Nav.Link>
              </Link>
              <Link to="/collection/2">
                <Nav.Link onClick={() => history.push("/collection/5")}>
                  Fortellinger fra Bibelen for barn
                </Nav.Link>
              </Link>
            </Nav>
            {isAuthenticated ? (
              <Form inline>
                <Button variant="outline-light" onClick={() => logout()}>
                  Logg ut
                </Button>
              </Form>
            ) : null}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default withRouter((props) => <AppNavbar {...props} />);
