import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import detectBrowser from "browser-detect";

import { Container, CardColumns, Image } from "react-bootstrap";

import { loader } from 'graphql.macro';

import CollectionCard from './CollectionCard';
import Loading from '../../Auth/Loading';
import BrowserWarning from '../BrowserWarning';

const GetCollections = loader('../../GraphQL/GetCollections.gql');

export default function Home(props) {

  const { loading, data } = useQuery(GetCollections);

  const collections = data ? data.getCollections : [];
  const collectionCards = collections.map(e => <CollectionCard collection={e} />);

  if(loading) {
    return <Loading />
  }

  const result = detectBrowser();
  const isBrowserRecommended = result.name === "chrome";

  return (
    <div>
      <Container>
        <div style={{ marginTop: "20px" }}>
          <h4 style={{ marginBottom: "20px" }}>Lydbøker</h4>
          <CardColumns>{collectionCards}</CardColumns>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Image
            style={{ float: "left", width: "70px" }}
            src="./icons/icon_book.svg"
            rounded
          />
          <div style={{ marginLeft: "100px" }}>
            <h5>Velkommen!</h5>
            <p>
              Velkommen til Forlagets arkiv med lydbøker. Vi håper samlingen
              kan bli til nytte for menighetens medlemmer. Forlaget jobber
              kontinuerlig med å forbedre tjenesten og med å berike arkivet
              med flere lydbøker.
            </p>
          </div>
        </div>
        <div style={{ marginTop: "40px" }}>
          <Image
            style={{ float: "left", width: "70px" }}
            src="./icons/icon_chrome.svg"
            rounded
          />
          <div style={{ marginLeft: "100px" }}>
            <h5>Nettleser</h5>
            <p>
              På grunn av begrensninger i enkelte nettlesere anbefales det å
              bruke Google Chrome. Eksempelvis støtter ikke Safari automatisk
              avspilling av lydspor. Du kan laste ned Chrome{" "}
              <a
                target="_blank"
                href="https://www.google.com/intl/no/chrome/"
              >
                her.
              </a>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}
