import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ListGroup } from 'react-bootstrap';

function PlaylistCard({ playlist, history }) {

  const { id, title, items } = playlist;

  const onPlaylistClick = () => {
    history.push(`/playlist/${id}`)
  };

  return (
    <ListGroup.Item onClick={() => onPlaylistClick()}>
      <p style={{ marginBottom: "0px", fontWeight: "600" }}>{title}</p>
      <p style={{ marginBottom: "0px", color: "	#696969", fontStyle: "italic", fontSize: "0.8rem" }}>{items.length} lydspor</p>
    </ListGroup.Item>
  );
}

PlaylistCard.propTypes = {
  playlist: PropTypes.shape().isRequired
};

PlaylistCard.defaultProps = {

};

export default withRouter(PlaylistCard);

