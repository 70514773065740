import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../Auth/PrivateRoute';
import AppNavbar from './AppNavbar'
import Home from './Home/Home'
import PlaylistContainer from './Playlist/PlaylistContainer';
import Collection from "./Collection/Collection";

import './Wrapper.css';

export default function Wrapper() {

  return (
    <div style={{ height: "100%" }}>
      <AppNavbar />

      <Switch>
        <PrivateRoute path="/" exact component={Home} />
        <PrivateRoute path="/playlist/:id(\d+)" exact component={PlaylistContainer} />
        <PrivateRoute path="/collection/:id(\d+)" exact component={Collection} />
      </Switch>
    </div >
  );

}
