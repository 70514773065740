import React, { useState } from 'react';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';

import { ListGroup } from 'react-bootstrap';

export default function Track({ isPlaying, track, onClick }) {

  const [isHovering, setIsHovering] = useState(false);

  const { id, title, description } = track;

  const faIcon = isPlaying ? faVolumeUp : isHovering ? null : null

  return (
    <ListGroup.Item as="li" key={id} style={{ backgroundColor: isPlaying ? "	#F5F5F5" : null, paddingRight: "0px", paddingLeft: "0px" }} action onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} onClick={() => onClick(track)}>
      {faIcon ? <FontAwesomeIcon icon={faIcon} style={{ marginLeft: "50px", marginRight: "10px", float: "right" }} /> : null}
      <p style={{ marginBottom: "0px", marginRight: "80px", fontWeight: "600" }}>{title}</p>
      <p style={{ marginBottom: "0px", color: "	#696969", fontStyle: "italic", fontSize: "0.8rem" }}>{description}</p>
    </ListGroup.Item >
  );
}