import React, { useState } from 'react';
import { ListGroup, Container } from 'react-bootstrap';
import PlaylistInfo from './PlaylistInfo';
import { Player } from '../Player/Player';
import Track from './Track';

export default function Playlist({ playlist }) {

  const { title, description, coverPhotoUrl, items, artists } = playlist;

  const [currentItem, setCurrentItem] = useState({ id: -1 });
  const [isPlaying, setIsPlaying] = useState(false);

  const onNextTrack = () => {
    const indexOfCurrentItem = items.map(e => e.id).indexOf(currentItem.id);

    if (indexOfCurrentItem === items.length - 1)
      return;

    setCurrentItem(items[indexOfCurrentItem + 1]);
  };

  const extra = `${items.length} lydspor`

  return (
    <Container>
      <div style={{ paddingBottom: "120px" }}>
        <div style={{ marginTop: "20px" }}>
          <PlaylistInfo title={title} subtitle={description} iconUrl={coverPhotoUrl} extra={extra} artists={artists} />
        </div>
        <div style={{ marginTop: "20px" }}>
          <ListGroup variant="flush" style={{ margin: "0px" }}>
            {items.map(item => <Track key={item.track.id} track={item.track} isPlaying={currentItem && currentItem.id === item.id} onClick={() => setCurrentItem(item)} />)}
          </ListGroup>
        </div>
        <div className="fixed-bottom" style={{ margin: "15px 0px 0px 0px" }}>
          <Player track={currentItem ? currentItem.track : null} onPlay={() => setIsPlaying(true)} onPause={() => setIsPlaying(false)} onEnd={() => onNextTrack()} />
        </div>
      </div>
    </Container>
  );
}
