import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NoSleep from 'nosleep.js';

import Plyr from "plyr";
import 'plyr/dist/plyr.css'

const noSleep = new NoSleep();
var player;

export function Player({ track, onEnd, onPlay, onPause }) {

  const { streamingUrl, title } = track ? track : { streamingUrl: "", title: "" };
  console.log(track);

  const startPlaying = () => {
    if(!streamingUrl || !title)
      return;

    player.source = {
      type: 'audio',
      title: title,
      muted: true,
      sources: [{ src: streamingUrl, type: 'audio/mp3' }]
    }

    player.play().catch(error => {
      console.error(error);
    });
  }

  useEffect(() => {
    player = new Plyr('#player', {
      controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
      playsInline: true
    });

    document.addEventListener('click', function enableNoSleep() {
      console.log("Enables Nosleep");
      document.removeEventListener('click', enableNoSleep, false);
      noSleep.enable();
    }, false);

    return () => { noSleep.disable(); }
  }, []);

  useEffect(() => {
    player.on("playing", onPlay);
    player.on("pause", onPause);
    player.on("ended", onEnd);

    return () => {
      player.off("playing", onPlay);
      player.off("pause", onPause);
      player.off("ended", onEnd);
    };
  }, [streamingUrl]);

  useEffect(() => {
    if(player) {

      document.addEventListener('click', function onClick() {
        console.log("Play");
        document.removeEventListener("click", onClick, false);
        startPlaying();
      }, false);

      document.getElementById("root").click();
    }
  }, [streamingUrl]);

  return (
    <div style={{ borderTop: "1px solid black" }}>
      <audio id="player" controls />
    </div>
  );
}

Player.propTypes = {
  track: PropTypes.shape(),
  onEnd: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
}