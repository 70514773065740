import React from 'react';
import { Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

function CollectionCard({ collection, history }) {
  const { id, title, description, coverPhotoUrl } = collection;

  return (
    <Card border="dark" onClick={() => history.push(`/collection/${id}`)}>
      <Card.Img
        variant="top"
        style={{ height: "280px", objectFit: "cover" }}
        src={coverPhotoUrl}
      />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default withRouter(CollectionCard)