import React from 'react';

export default function PlaylistInfo(props) {

  const { title, extra } = props;

  return (
    <div>
      <h4 style={{ fontWeight: "600" }}>{title}</h4>
      <span style={{ color: "#696969" }}>{extra}</span>
    </div>
  );
}